import "@hotwired/turbo-rails"
import 'arrive'
import * as bootstrap from 'bootstrap'

// stimulus support
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
const application = Application.start()
const controllers = import.meta.glob('~/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// main menu
document.arrive('#menu-inner', { existing: true }, function(el) {
  ['show.bs.collapse', 'hide.bs.collapse'].forEach(function(evt){
    el.addEventListener(evt, function(event){
      document.querySelectorAll('.toggle-icon').forEach(function(el){
        el.classList.toggle('d-none')
      })
    })
  })
})

document.arrive('nav.menu a', { existing: true }, function(el) {
  el.addEventListener('click', function(event) {
    var menu = document.querySelector('#menu-inner')
    var coll = bootstrap.Collapse.getInstance(menu)
    if (coll) { coll.hide() }
  })
})

// scroll to anchor on load when needed
// document.addEventListener('turbo:load', function() {
//   if (window.location.hash) {
//     var el = document.querySelector(window.location.hash)
//     if (el) { el.scrollIntoView(true) }
//   }
// })

// menu links - handle scroll when on the same page
// document.arrive('nav.menu a', { existing: true }, function(el) {
//   if (el.pathname === window.location.pathname) {
//     el.addEventListener('click', function(event) {
//       event.preventDefault()
//       document.querySelector(el.hash).scrollIntoView(true)
//     })
//   }
// })

// Google map
import iconImg from '~/images/marker.svg'
document.arrive('.office-map', { existing: true }, function(el) {
  var coords = new google.maps.LatLng(el.dataset.latitude, el.dataset.longitude)
  var opts = {
    auto_adjust: true,
    auto_zoom: false,
    center: coords,
    zoom: 17
  }
  var map = new google.maps.Map(el, opts)
  var marker = new google.maps.Marker({ position: coords, map: map, title: el.dataset.name, icon: {url: iconImg, scaledSize: new google.maps.Size(100,100)} })
  var info = new google.maps.InfoWindow({ content: `<strong>${el.dataset.name}</strong><br>${el.dataset.address}` })
  google.maps.event.addListener(marker, 'click', function () { info.open(map, marker) })
})

// called after GoogleMap is loaded, it is now compulsory to have some callback defined
window.initMap = () => {}

// sharer.js
import 'sharer.js'
document.addEventListener('turbo:load', function(event) { Sharer.init() })

// plausible.io + Turbo
document.addEventListener('turbo:load', function() {
  // if (! typeof plausible === 'undefined') { plausible('pageview') }
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
})

// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://www.nuvadis.com']):not([href^='http://nuvadis.com']):not([href^='https://www.nuvadis.com']):not([href^='https://nuvadis.com'])"
  el.querySelectorAll(selector).forEach(function (e) {e.setAttribute("target", "_blank") })
})

// set proper scroll-margin on <html> to handle fixed header
function adjustScrollPadding() {
  const html = document.querySelector('html')
  const header = document.querySelector('header')
  if (header) {
    html.style.scrollPaddingTop = `${header.getBoundingClientRect().height}px`
  }
}

if (!document.body.classList.contains('auth')) {
  // do not run this on login screens
  window.addEventListener('resize', adjustScrollPadding)
  document.addEventListener('turbo:load', adjustScrollPadding)
}

// animations
import '~/javascript/animations'

// CSS Styles
import '~/stylesheets/website.scss'
